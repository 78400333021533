import React from 'react';
import styles from './officeInfo.module.scss';

const OfficeInfo = () => {
  const email = 'hope@mindsetfamilytherapy.com';
  const phone = '(801) 427-1054';
  const fax = '(855) 221-3659';
  const officeAddress = (
    <span>
      3355 North University Avenue, Suite&nbsp;100
      <br />
      Hartford Building at Jamestown&nbsp;Square
      <br /> Provo, UT 84604
    </span>
  );

  return (
    <div className={styles.container}>
      <div className={styles.infoBox}>
        <p>Mindset Family Therapy</p>
        <p className={styles.lightweight}>{officeAddress}</p>
        <p>
          Email: <span className={styles.lightweight}>{email}</span>
        </p>
        <p>
          Phone: <span className={styles.lightweight}>{phone}</span>
        </p>
        <p>
          Fax: <span className={styles.lightweight}>{fax}</span>
        </p>
      </div>
    </div>
  );
};

export default OfficeInfo;
